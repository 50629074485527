<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="SVGID_1_" x1="144.7497" x2="34.4566" y1="70.6126" y2="110.756">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M50.9,106.8l-5.5,23.5c-0.7,3.2,1.3,6.3,4.5,7c0.4,0.1,0.8,0.1,1.2,0.1c0.4,0,0.8,0,1.2,0l23.7-5.4l45.5-45.3  L96.2,61.5L50.9,106.8z M135.9,64.1L119,47.2c-2.3-2.3-6.1-2.3-8.4,0l-9.4,9.4l25.3,25.3l9.4-9.4C138.3,70.2,138.3,66.4,135.9,64.1  C136,64.1,136,64.1,135.9,64.1L135.9,64.1z" style="fill:url(#SVGID_1_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>